<template>

	<Popup />

	<!-- 컨텐츠 START -->
	<div class="content_container main">

		<!--메인배너-->
		<div class="main_banner">
			<img class="banner_img" src="@/assets/images/main/main_banner_01.jpg" alt="main_banner1" />
			<img class="banner_img" src="@/assets/images/main/main_banner_02.jpg" alt="main_banner2" />
			<img class="banner_img" src="@/assets/images/main/main_banner_03.jpg" alt="main_banner3" />

			<div class="main_text">
				<h1>"내일을 여는 금융회사"</h1>
				<p class="name_en">&nbsp;무궁화<span>캐피탈</span></p>
				<!-- <p class="slogan"><strong>M</strong>ugunghwa<strong> C</strong>apital</p> -->
			</div>
		</div>
		<!--//메인배너-->

		<!--상품 안내 시작-->
		<div class="section01">
			<h1>상품 안내</h1>

			<!--1.투자금융-->
			<div class="p_banner">
				<img src="@/assets/images/main/illu_02.svg" alt="투자금융">
				<span>투자금융</span>
				<p>
					기업 지분을 연계로
					조달금리를 인하하고자 하는
					기업 고객 대상 금융 서비스
				</p>
				<router-link to="/product/investmezzanine">
					자세히 보기
				</router-link>
			</div>
			<!--//1.투자금융-->

			<!--2.기업금융-->
			<div class="p_banner">
				<img src="@/assets/images/main/illu_03.svg" alt="기업금융">
				<span>기업금융</span>
				<p>
					기업 운영 자금을 지원하는
					기업 고객 대상 금융 서비스
				</p>
				<router-link to="/product/enterprisenormal">
					자세히 보기
				</router-link>
			</div>
			<!--//2.일반 금융-->

			<!--3.할부리스-->
			<div class="p_banner">
				<img src="@/assets/images/main/illu_04.svg" alt="할부리스">
				<span>할부리스</span>
				<p>
					효율적인 기업의 시설 및 설비운영을 지원하는 금융서비스
				</p>
				<router-link to="/product/leasenormal">
					자세히 보기
				</router-link>
			</div>
			<!--//3.할부리스-->

		</div>
		<!--//상품 안내 끝-->

		<!--온라인 상담 시작-->
		<div class="section02">
			<div class="section_banner">
				<span></span>
				<span></span>
			</div>
			<p>
				든든한 동반자, <span>무궁화캐피탈</span>과<br/>
				성공적인 비즈니스를 동행하세요.
			</p>
			<div class="btn_wrapper">
				<router-link to="/customer/onlinecontact">
					<button type="button" class="btn_common">온라인 상담 연결하기</button>
				</router-link>
			</div>
		</div>
		<!--온라인 상담 끝-->

		<!--기업 소개 시작-->
		<div class="section03">
			<div class="section03_inner">
				<span>고객님의 든든한 동행 파트너</span>
				<p>행복과 신뢰를 전해드릴 수 있도록<br/>고객 가치를 혁신해 나갑니다.</p>
				<router-link to="/company/companyintro">
					<button type="button">기업소개 보기</button>
				</router-link>
			</div>
		</div>
		<!--//기업 소개 끝-->

		<!--고객센터, 공지사항 시작-->
		<div class="section04">
			<!--고객센터-->
			<div class="left">
				<h1>고객센터</h1>
				<p>
					무궁화캐피탈은 고객님께<br/>
					<span>친절한 상담</span>을 약속드립니다.
				</p>

				<div class="contact">
					<img src="@/assets/images/main/icon_01.svg" alt="telephone">
					<ul>
						<li>대표전화</li>
						<li>02-2047-7100</li>
						<li>평일 : 08:30 ~ 17:30 (주말 / 공휴일 휴무)</li>
					</ul>
				</div>
				<div class="contact">
					<img src="@/assets/images/main/icon_02.svg" alt="email">
					<ul>
						<li>이메일</li>
						<li>support@mghcap.co.kr</li>
						<li>대출관련문의</li>
					</ul>
				</div>
			</div>

			<!--공지사항-->
			<div class="right">
				<!--공지사항-->
				<div class="notice">
					<div class="n_title">
						<h1>공지사항</h1>
						<router-link to="/customer/notice">
							더보기
						</router-link>
					</div>
					<ul v-if="this.managedisclosure.length == 0">
						<li>게시글이 없습니다.</li>
					</ul>
					<ul v-else>
						<li v-for="(item, index) in this.noticelist" :key="index">
							<a @click="openDetail(item, 'notice')"><span>{{item.title}}</span><span>{{ dateFormat(item.enrollment_date) }}</span></a>
						</li>
					</ul>
				</div>
				<!--경영공시-->
				<div class="notice">
					<div class="n_title">
						<h1>경영공시</h1>
						<router-link to="/company/managedisclosure">
							더보기
						</router-link>
					</div>
					<ul v-if="this.noticelist.length == 0">
						<li>게시글이 없습니다.</li>
					</ul>
					<ul v-else>
						<li v-for="(item, index) in this.managedisclosure" :key="index">
							<a @click="openDetail(item, 'manageDisclosure')"><span>{{item.title}}</span><span>{{ dateFormat(item.enrollment_date) }}</span></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!--//고객센터, 공지사항 끝-->
	</div>
	<!-- 컨텐츠 END -->

</template>

<script>
import Popup from '@/components/Popup'
import { dateFormat } from '@/utils/dateFormat'

export default {
	name: 'Home',
	components: {
		Popup,
	},
	mixins : [ dateFormat ],
	data(){
        return {
            managedisclosure : [],
			noticelist : [],
        }
    },
	mounted(){
		this.fetchBoardData();
	},
	methods : {
		fetchBoardData(){
			const noticeEl = document.querySelectorAll('.section04 .right .notice');
			for(let i=0;i<noticeEl.length; i++){
				noticeEl[i].classList.add('loading');
			}

			this.axios.get(`/getMainList`)
			.then(res=>{
				for(let i=0;i<noticeEl.length; i++){
					noticeEl[i].classList.remove('loading');
				}
				if(res.data.success){
					this.managedisclosure= res.data.managedisclosure;
					this.noticelist= res.data.noticelist;
				}
				else{
					alert(res.data.message);
				}
			})
		},
		openDetail(item, whichBoard){
            const pk = whichBoard == 'notice' ? item.noticelist_pk   //공지사항
                        : item.managedisclosure_pk  //경영공시
            this.$router.push({
				path : '/detailBoard',
				query : {
					whichBoard : whichBoard,
					page : 1,
					pk : pk,
				}
			});
        },
	}
}
</script>

<style scoped src="@/assets/css/home.css">
</style>