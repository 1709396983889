import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Home from '../views/Home.vue'

function setInitPage(to, next){
  // 진입 시 페이지 page 쿼리 없거나 page<=0이면 page=1 쿼리 페이지로 교체 (최대는 fetchBoardData안에 설정해놓음)
  if(isNaN(to.query.page) || to.query.page <= 0){
    next({ path: to.path, query: { page : 1 } })
  } else {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  { // 회사 소개 - 회사개요
    path: '/company/companyintro',
    name: 'CompanyIntroduction',
    component: () => import(/* webpackChunkName: "CompanyIntroduction" */ '../views/Company/CompanyIntroduction')
  },
  { // 회사 소개 - CEO 인사말
    path: '/company/ceo',
    name: 'Ceo',
    component: () => import(/* webpackChunkName: "Ceo" */ '../views/Company/Ceo')
  },
  { // 회사 소개 - 경영공시
    path: '/company/managedisclosure',
    name: 'ManageDisclosure',
    component: () => import(/* webpackChunkName: "ManageDisclosure" */ '../views/Company/ManageDisclosure'),
    beforeEnter: (to, from, next) => {
      setInitPage(to, next);
    }
  },
  { // 회사 소개 - 오시는길
    path: '/company/location',
    name: 'Location',
    component: () => import(/* webpackChunkName: "Location" */ '../views/Company/Location')
  },
  { // 회사 소개 - 채용정보 - 리스트
    path: '/company/employment',
    name: 'Employment',
    component: () => import(/* webpackChunkName: "Employment" */ '../views/Company/Employment'),
    beforeEnter: (to, from, next) => {
      setInitPage(to, next);
    }
  },

  { // 상품 안내 - 투자금융
    path: '/product/investprivate',
    name: 'InvestPrivate',
    component: () => import(/* webpackChunkName: "InvestPrivate" */ '../views/Product/InvestPrivate')
  },
  { // 상품 안내 - 기업금융 - 일반대출
    path: '/product/enterprisenormal',
    name: 'EnterpriseNormal',
    component: () => import(/* webpackChunkName: "EnterpriseNormal" */ '../views/Product/EnterpriseNormal')
  },
  { // 상품 안내 - 기업금융 - 부동산 PF
    path: '/product/enterpriserealty',
    name: 'EnterpriseRealty',
    component: () => import(/* webpackChunkName: "EnterpriseRealty" */ '../views/Product/EnterpriseRealty')
  },
  { // 상품 안내 - 할부리스 - 일반리스
    path: '/product/leasenormal',
    name: 'LeaseNormal',
    component: () => import(/* webpackChunkName: "LeaseNormal" */ '../views/Product/LeaseNormal')
  },
  { // 상품 안내 - 할부리스 - 할부금융
    path: '/product/Leasefinance',
    name: 'LeaseFinance',
    component: () => import(/* webpackChunkName: "LeaseFinance" */ '../views/Product/LeaseFinance')
  },
  { // 상품 안내 - 투자금융 - mezzanine
    path: '/product/investmezzanine',
    name: 'InvestMezzanine',
    component: () => import(/* webpackChunkName: "InvestMezzanine" */ '../views/Product/InvestMezzanine')
  },
  { // 상품 안내 - 투자금융 - 신기술투자
    path: '/product/investpef',
    name: 'InvestPef',
    component: () => import(/* webpackChunkName: "InvestPef" */ '../views/Product/InvestPef')
  },

  { // 고객센터 - 공지사항(리스트)
    path: '/customer/notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "Notice" */ '../views/Customer/Notice'),
    beforeEnter: (to, from, next) => {
      setInitPage(to, next);
    }
  },
  { // 고객센터 - 서식 자료실
    path: '/customer/dataroom',
    name: 'DataRoom',
    component: () => import(/* webpackChunkName: "DataRoom" */ '../views/Customer/DataRoom'),
    beforeEnter: (to, from, next) => {
      setInitPage(to, next);
    }
  },
  { // 고객센터 - 금융소비자보호
    path: '/customer/financeprotect',
    name: 'FinanceProtect',
    component: () => import(/* webpackChunkName: "FinanceProtect" */ '../views/Customer/FinanceProtect')
  },
  { // 고객센터 - 금리인하요구권
    path: '/customer/nego',
    name: 'Nego',
    component: () => import(/* webpackChunkName: "Nego" */ '../views/Customer/Nego')
  },
  { // 고객센터 - 청약철회권
    path: '/customer/withdraw',
    name: 'WithDraw',
    component: () => import(/* webpackChunkName: "WithDraw" */ '../views/Customer/WithDraw')
  },
  { // 고객센터 - 자료열람청구권
    path: '/customer/dataopen',
    name: 'DataOpen',
    component: () => import(/* webpackChunkName: "DataOpen" */ '../views/Customer/DataOpen')
  },
  { // 고객센터 - 위법계약해지권
    path: '/customer/termination',
    name: 'Termination',
    component: () => import(/* webpackChunkName: "Termination" */ '../views/Customer/Termination')
  },
  { // 고객센터 - 약관 및 규정(여신거래 약관)
    path: '/customer/termscredit',
    name: 'TermsCredit',
    component: () => import(/* webpackChunkName: "TermsCredit" */ '../views/Customer/TermsCredit'),
    beforeEnter: (to, from, next) => {
      setInitPage(to, next);
    }
  },
  { // 고객센터 - 약관 및 규정(고객권리 관리문)
    path: '/customer/termscustomer',
    name: 'TermsCustomer',
    component: () => import(/* webpackChunkName: "TermsCustomer " */ '../views/Customer/TermsCustomer')
  },
  { // 고객센터 - 고객안내문
    path: '/customer/customernotice',
    name: 'CustomerNotice',
    component: () => import(/* webpackChunkName: "CustomerNotice " */ '../views/Customer/CustomerNotice')
  },
  { // 고객센터 - 고객문의
    path: '/customer/onlinecontact',
    name: 'OnlineContact',
    component: () => import(/* webpackChunkName: "OnlineContact" */ '../views/Customer/OnlineContact')
  },

  { // 개인정보 - 개인정보취급방침
    path: '/privacy/privacypolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/Privacy/PrivacyPolicy')
  },
  { // 개인정보 - 개인정보보호규정
    path: '/privacy/privacyprotection',
    name: 'PrivacyProtection',
    component: () => import(/* webpackChunkName: "PrivacyProtection" */ '../views/Privacy/PrivacyProtection')
  },
  { // 개인정보 - 이메일 무단수집거부
    path: '/privacy/emaildenial',
    name: 'EmailDenial',
    component: () => import(/* webpackChunkName: "EmailDenial" */ '../views/Privacy/EmailDenial')
  },

  { // 게시판 - 디테일 보드
    path: '/detailboard',
    name: 'DetailBoard',
    component: () => import(/* webpackChunkName: "EmailDenial" */ '../views/DetailBoard'),
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  // 항상 라우트되면 페이지 맨 위로 가도록
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition; // 돌아가기 누르때는 원래 위치로
    }
    // 쿼리만 다를 때는 스크롤 업 안함
    if(to.matched[0]?.path===from.matched[0]?.path && to.path!=='/') {
      return;
    }
    if(to.path===from.path || to.path==='/'){
      window.scrollTo({top : 0, behavior : 'smooth'});
      return;
    }

    window.scrollTo({top : 0, behavior : 'smooth'});
  }
})

// 페이지 변경시 로딩 시작
router.beforeEach(() => {
  store.commit('loading/startDelayLoading'); 
})
// 페이지 변경시 로딩 끝냄
router.afterEach(() => {
  store.commit('loading/finishLoading');
})

export default router
