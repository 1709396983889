export default {
  namespaced: true,
  state: {
    loading: true, // 로딩
    delayTimeID: 0,
  },
  mutations: {
    startLoading(state) { // 로딩 시작
      state.loading = true;
    },
    startDelayLoading(state, delay=200){ // 지연 로딩 시작
      clearTimeout(this.delayTimeID);
      state.delayTimeID = setTimeout(() => {
        state.loading = true;
      }, delay);
    },
    finishLoading(state) {  // 로딩 끝
      clearTimeout(state.delayTimeID);
      state.loading = false;
    },
  }
}