import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

// 기본 url
axios.defaults.baseURL = 'https://admin.mghcap.co.kr/api';

const app = createApp(App);
app.config.globalProperties.axios = axios;
app.use(store).use(router).mount('#app');
