<template>
    <!-- 헤더 START -->
	<div class="header_container" id="header_container"
		@mouseenter="checkDesktop('add')"
		@mouseleave="checkDesktop('remove')"
	>
		<header>
			<router-link class="ci" to="/"
				@click="toggleActivity('remove')"
			></router-link>
			<button type="button" class="btn_list"
				@click="toggleActivity('toggle')"
			></button>
			<nav class="gnb">
				<ul class="depth1">
					<li id="company">
						<a @click="toggleActivity('add'); toggleDepthOn('company')">회사 소개</a>
						<ul class="depth2">
							<li>
								<router-link to="/company/companyintro"
									@click="toggleActivity('remove')"
								>회사개요</router-link>
							</li>
							<li>
								<router-link to="/company/ceo"
									@click="toggleActivity('remove')"
								>CEO 인사말</router-link>
							</li>
							<li>
								<router-link to="/company/managedisclosure?page=1"
									@click="toggleActivity('remove')"
								>경영공시</router-link>
							</li>
							<li>
								<router-link to="/company/location"
									@click="toggleActivity('remove')"
								>오시는길</router-link>
							</li>
							<li>
								<router-link to="/company/employment?page=1"
									@click="toggleActivity('remove')"
								>채용정보</router-link>
							</li>
						</ul>
					</li>
					<!-- <li id="product">
						<a @click="toggleActivity('add'); toggleDepthOn('product')">상품 안내</a>
						<ul class="depth2">
							<li>
								<router-link to="/product/enterprisenormal"
									@click="toggleActivity('remove')"
								>일반대출</router-link>
							</li>
							<li>
								<router-link to="/product/enterpriserealty">부동산 PF</router-link>
							</li>
							<li>
								<router-link to="/product/leasenormal"
									@click="toggleActivity('remove')"
								>일반리스</router-link>
							</li>
							<li>
								<router-link to="/product/leasefinance"
									@click="toggleActivity('remove')"
								>할부금융</router-link>
							</li>
							<li>
								<router-link to="/product/investmezzanine"
									@click="toggleActivity('remove')"
								>Mezzanine 투자</router-link>
							</li>
							<li>
								<router-link to="/product/investpef"
									@click="toggleActivity('remove')"
								>신기술투자</router-link>
							</li>
						</ul>
					</li> -->
					<li id="product_enterprise">
						<a @click="toggleActivity('add'); toggleDepthOn('product_enterprise')">기업금융</a>
						<ul class="depth2">
							<li>
								<router-link to="/product/enterprisenormal"
									@click="toggleActivity('remove')"
								>일반대출</router-link>
							</li>
							<li>
								<router-link to="/product/enterpriserealty"
									@click="toggleActivity('remove')"
								>부동산 PF</router-link>
							</li>
						</ul>
					</li>
					<li id="product_lease">
						<a @click="toggleActivity('add'); toggleDepthOn('product_lease')">할부리스</a>
						<ul class="depth2">
							<li>
								<router-link to="/product/leasenormal"
									@click="toggleActivity('remove')"
								>일반리스</router-link>
							</li>
							<li>
								<router-link to="/product/leasefinance"
									@click="toggleActivity('remove')"
								>할부금융</router-link>
							</li>
						</ul>
					</li>
					<li id="product_invest">
						<a @click="toggleActivity('add'); toggleDepthOn('product_invest')">투자금융</a>
						<ul class="depth2">
							<li>
								<router-link to="/product/investmezzanine"
									@click="toggleActivity('remove')"
								>Mezzanine 투자</router-link>
							</li>
							<li>
								<router-link to="/product/investpef"
									@click="toggleActivity('remove')"
								>신기술투자</router-link>
							</li>
						</ul>
					</li>
					<li id="customer">
						<a @click="toggleActivity('add'); toggleDepthOn('customer')">고객센터</a>
						<ul class="depth2">
							<li>
                                <router-link to="/customer/notice?page=1"
									@click="toggleActivity('remove')"
								>공지사항</router-link>
							</li>
							<li>
								<router-link to="/customer/dataroom?page=1"
									@click="toggleActivity('remove')"
								>서식 자료실</router-link>
							</li>
							<li>
								<router-link to="/customer/financeprotect"
									@click="toggleActivity('remove')"
								>금융소비자보호</router-link>
							</li>
							<li>
								<router-link to="/customer/termscredit?page=1"
									@click="toggleActivity('remove')"
								>약관 및 규정</router-link>
							</li>
							<!-- <li>
								<router-link to="/customer/customernotice"
									@click="toggleActivity('remove')"
								>고객안내문</router-link>
							</li> -->
							<li>
                                <router-link to="/customer/onlinecontact"
									@click="toggleActivity('remove')"
								>온라인 상담</router-link>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
			<div class="gnb_bar"></div>
		</header>
	</div>
	<!-- 헤더 END -->
</template>

<script>
export default {
    name : "Footer",
    mounted(){
        window.addEventListener('scroll', this.toggleScroll);
    },
	unmounted(){
        window.removeEventListener('scroll', this.toggleScroll);
	},
    watch:{
		// 다른 페이지로 넘어갈 때 헤더 닫기(뒤로가기 때문)
        $route() {
            this.toggleActivity('remove');
        }
    },
    methods:{
		// 스크롤시 헤더 메뉴
        toggleScroll(){
			const header_container = document.getElementById('header_container');
            if(window.scrollY>0){
                header_container.classList.add('scroll')
            } else{
                header_container.classList.remove('scroll');
            }
        },
		// 헤더 submenu 활성화
		toggleActivity(action){
			const header_container = document.getElementById('header_container');
			if(action == 'add'){
				header_container.classList.add('activity');
				document.querySelector('body').classList.add('body_hidden');
			} else if(action == 'remove'){
				header_container.classList.remove('activity');
				document.querySelectorAll('.depth1>li').forEach(el => {
					el.classList.remove('depth_on');
				})
				document.querySelector('body').classList.remove('body_hidden');
			} else{
				header_container.classList.toggle('activity');
				document.querySelector('body').classList.toggle('body_hidden');
			}
		},
		// 데스크탑이면 마우스 호버시 헤더 submenu 활성화
		checkDesktop(action){
			// 햄버거 버튼 display 속성으로 체크
			const btn_list = document.querySelector(".btn_list");
			let display = window.getComputedStyle(btn_list, null).display;

			if(display == 'none'){
				this.toggleActivity(action);
			}
		},
		// 모바일 화면에서 헤더 submenu 펼치는 함수
		toggleDepthOn(which){
			document.getElementById(`${which}`).classList.toggle('depth_on');
		},
    }
}
</script>

<style scoped src="@/assets/css/components/header.css">
</style>