<template>
    <!-- 푸터 START -->
	<div class="footer_container">
		<footer>
			<div class="ci">
				<img src="@/assets/images/common/ci_white.svg" alt="ci">
			</div>
			<div class="cs_menu">
				<ul>
					<li>
						<router-link to="/privacy/privacypolicy">
							개인정보취급방침
						</router-link>
					</li>
					<li>
						<router-link to="/privacy/privacyprotection">
							개인정보보호규정
						</router-link>
					</li>
					<li>
						<router-link to="/privacy/emaildenial">
							이메일 무단수집거부
						</router-link>
					</li>
					<li>
						<router-link to="/customer/termscredit?page=1">
							이용약관
						</router-link>
					</li>
				</ul>
			</div>
			<div class="info_detail">
				<p><span>본사 : 서울특별시 영등포구 여의대로 24, 10층 </span><span>(여의도동, 전국경제인연합회)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>법인명 : 무궁화캐피탈 주식회사&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>대표자 : 이정호</p>
				<p><span>사업자등록번호 : 476-87-00402&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>고객센터전화번호 : 02-2047-7100&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>Fax번호 : 02-783-6189</span></p>
				<p>COPYRIGHT Ⓒ MUGUNGHWA CAPITAL. ALL RIGHTS RESERVED.</p>
			</div>			
		</footer>
	</div>
	<!-- 푸터 END -->
</template>

<script>
export default {
    name : "Footer"
}
</script>

<style scoped src="@/assets/css/components/footer.css">
</style>