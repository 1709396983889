export default {
  namespaced: true,
  state: {
    alertContent: "", // 알림창 내용
    confirmContent: "", // 질문창 내용
    confirmCallback: null,
    errorContent: "", // 에러창 내용
    checkContent: [] // 메세지 창 내용
  },
  mutations: {
    // 알림창
    alert(state, payload) {
      state.alertContent = payload;
      this.alertTimeout = setTimeout(() => {
        state.alertContent = "";
      }, 4000);
    },
    closeAlert(state) {
      state.alertContent = "";
      clearTimeout(this.alertTimeout);
    },

    // 확인(confirm)창
    confirm(state, payload) {
      state.confirmContent = payload.content;
      state.confirmCallback = payload.callback;
    },
    confirmContinue(state) {
      state.confirmContent = "";
      state.confirmCallback();
    },
    confirmCancel(state) {
      state.confirmContent = "";
      state.confirmCallback = null;
    },

    // 에러창
    error(state, payload) {
      state.errorContent = payload;
    },
    closeError(state) {
      state.errorContent = "";
    },
    
    // check box
    check(state, payload) {
      console.log(payload)
      if(state.checkContent.length > 10) return;

      state.checkContent.push(payload);
      setTimeout(() => {
        state.checkContent.shift();
      }, 2000);
    },
  }
}