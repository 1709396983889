export const dateFormat = {
    methods: {
        dateFormat(date){
            if(!date) {
                return '-';
            } else {
                return date.slice(0,10);
            }
        }
    },
}