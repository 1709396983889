<template>
    <!-- 모달팝업 START -->
	<div class="popup_container" v-if="this.popupOpen && this.popupContent.length > 0">
        <div class="bg"></div>
		<div class="popup">
			<div class="popup_close">
				<label>
					<input type="checkbox" v-model="todayCheck" />
					<span>오늘 하루 보지않기</span>
				</label>
				<button class="close_btn" type="button" @click="closePopup"></button>
			</div>
            <div class="popup_content"
                v-if="this.popupContent.length == 1"
            >
                <div class="slide">
                    <div class="img_wrapper" :style="this.popupContent[0].link ? {cursor: 'pointer'} : {}" @click="openBlank(this.popupContent[0].link)">
                        <img :src="this.popupContent[0].path + this.popupContent[0].save_name" class="img_map" :alt="this.popupContent[0].original_name" />
                    </div>
                </div>
            </div>
			<swiper class="popup_content"
                v-bind="swiperOption"
                v-else
            >
				<swiper-slide class="slide" v-for="(item,index) in this.popupContent" :key="index">
                    <div class="img_wrapper" :style="this.popupContent[0].link ? {cursor: 'pointer'} : {}" @click="openBlank(item.link)">
                        <img :src="item.path + item.save_name" class="img_map" :alt="item.original_name" />
                    </div>
                </swiper-slide>
			</swiper>
		</div>
	</div>
	<!-- 모달팝업 END -->
</template>

<script>
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import "swiper/swiper-bundle.min.css"
SwiperCore.use([ Pagination,Autoplay ]);

export default {
    components: {
		Swiper,
		SwiperSlide
	},
    mounted(){
        if(this.getCloseForToday()){
            this.popupOpen = false;
        }
        this.fetchData();
    },
    data(){
		return {
			popupOpen : true,
            todayCheck : false,
            popupContent : [],

			swiperOption : {
				loop:true,
				pagination : { clickable : true },
				autoplay:{
					delay : 3500, 
					disableOnInteraction : false 
				}
			}
		}
	},
    methods:{
        openBlank(link){
            if (link) {
                window.open(link);
            } else {
                return false;
            }
        },
        fetchData(){
            this.axios.get(`/getLayerpopup`)
                .then(res=>{
                    if(res.data.success){
                        this.popupContent = res.data.data;
                    }
                    else{
                        alert(res.data.message);
                    }
                })
        },
        closePopup(){
            this.popupOpen = false;

            if(this.todayCheck){
                this.setCloseForToday();
            }
        },
        setCloseForToday() {
            // 하루로 세팅
            const exdate = new Date();
            exdate.setDate(exdate.getDate() + 1);

            const cookie_value = escape(true) + '; expires=' + exdate.toUTCString();
            document.cookie = 'closeForToday' + '=' + cookie_value;
        },
        getCloseForToday() {
            let x, y;
            let val = document.cookie.split(';');

            for (let i = 0; i < val.length; i++) {
                x = val[i].substr(0, val[i].indexOf('='));
                y = val[i].substr(val[i].indexOf('=') + 1);
                x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
                if (x == 'closeForToday') {
                    return unescape(y); // unescape로 디코딩 후 값 리턴
                }
            }
        }
    }
}
</script>

<style scoped src="@/assets/css/components/popup.css">
</style>