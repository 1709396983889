<template>
	<Loading />
	<Header />

	<router-view />

	<Footer />
</template>

<script>
import Loading from '@/components/Loading'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
	components:{
		Loading,
		Header,
		Footer,
	},
	mounted(){
    this.axios.post('https://jsonip.com/')  // clientIP 알아냄
    .then(res => {
      const clientIP = res.data.ip;
      this.axios.post('/saveVisitor', { // 방문자 저장
        ip: clientIP
      })
    }).catch(error => {
      console.error(error, '방문자 저장에 실패했습니다');
    })
  }
}
</script>

<style>
@import './assets/css/reset.css';
@import './assets/css/common.css';
@import './assets/css/layout.css';
</style>